import React from 'react';
import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';

import iconTick from '../assets/images/svg/tick.svg';
import Faq from '../components/Faq';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';

import imgDots from '../assets/images/life-in-barcelona/Dots.svg';
import imgPhoto1 from '../assets/images/covid-19/Photo 2.jpg';
import img1 from '../assets/images/covid-19/kelly-sikkema-iHYs3h94MNE-unsplash.jpg';
import img2 from '../assets/images/covid-19/louis-hansel-shotsoflouis-wbIrp4c_8Qw-unsplash.jpg';
import img3 from '../assets/images/covid-19/pexels-ivan-samkov-4240606.jpg';
import imgBenefit1 from '../assets/images/covid-19/Reduced prices on our courses.svg';
import imgBenefit2 from '../assets/images/covid-19/Increased our online offering.svg';
import imgBenefit3 from '../assets/images/covid-19/More flexible refund policy.svg';

import '../assets/styles/pages/covid-page.scss';

const listFaq5 = `
                <div class='block_r'>
                  <p class="c-text-16">
                    We recommend coming to Barcelona to complete your TEFL course once you are fully vaccinated. This will keep you safe and is one of the accepted requirements for entry to Spain. Once you are here you can get qualified, find work and enjoy this wonderful city as normal!
                  </p>
                </div>
                `;

const listFaq4 = `
<div class="block_r">
<p class="c-text-16">
  Please check the Spain Travel Health website for up-to-date information: 
  <a href="https://www.spth.gob.es/" target="_blank" class="red-link">
    https://www.spth.gob.es/
  </a>
</p>
</div>
                `;

const listFaq3 = `
<div className="block_r">
<p className="c-text-16">
  Absolutely! Spain is currently leading Europe in the number of people fully vaccinated and continues to take health measures seriously. Mask wearing is still mandatory indoors and on public transport and bars and restaurants have increased their capacity for outdoor seating. Most of social life happens outdoors in Spain, which means less chance of infection.
  <br />
  <br />
Buildings are generally well ventilated and businesses have regular cleaning protocols. 

</p>
</div>
                `;

const listFaq2 = `
<div class="block_r">
<p class="c-text-16">
  In short, yes, there is a large demand for online teachers and it is
  relatively easy to find work.
  <br />
  <br />
  <h4 class="block_r__list-title">
    There are few points worth remembering before investing in a TEFL course:
  </h4>
  <ul class="block_r__list">
    <li class="list__item">
      Demand for online teachers was consistently rising before the pandemic and
      is now at record levels.
    </li>
    <li class="list__item">
      Education is more or less recession proof; many people use their downtime
      to study or retrain.
    </li>
    <li class="list__item">
      Governments have boosted funding for their Covid retraining schemes. See
      <a
        class="red-link"
        href="https://www.gov.uk/government/news/major-expansion-of-post-18-education-and-training-to-level-up-and-prepare-workers-for-post-covid-economy"
        target="_blank"
      >
        UK Government
      </a>
      and
      <a
        class="red-link"
        href="https://www.regionalskills.ie/covid-19-government-supports/"
        target="_blank"
      >
        Irish Government
      </a>
      websites for more information.
    </li>
    <li class="list__item">
      The training and qualification you get now is valid forever and you can
      use it to find online or classroom work all over the world.
    </li>
  </ul>
</p>
</div>`;

const listFaq1 = `
<div class="block_r">
<p class="c-text-16">
  The reality is that many schools are starting to open back up and are working with  reduced student numbers. Some are continuing to offer classes 100% online but our experience is that students are keen to get back to the classroom for in-person classes. 
  <br />
  <br />
In Barcelona, mask wearing is still mandatory indoors, so you’ll be expected to wear a face mask / shield during your lesson and your students will have to wear a face mask too. Classes will be limited to 6 (5 students and the teacher) and there will be cleaning protocols in place between each class.
  <br />
  <br />
In terms of finding a job in a school, the demand is growing and it’s a case of being persistent and being in the right place at the right time
</p>
</div>
                `;

const faqs = [
  {
    id: 1,
    title: 'What Is The Current Reality For Teaching English Overseas?',
    description: listFaq1,
  },
  {
    id: 2,
    title: 'The Economy Is Weak. Is Now A Good Time To Invest In A TEFL Course?',
    description: listFaq2,
  },
  {
    id: 3,
    title: 'Is it safe to travel to Spain just now?',
    description: listFaq3,
  },
  {
    id: 4,
    title: 'What are the current restrictions and requirements for travelling to Spain?',
    description: listFaq4,
  },
  {
    id: 5,
    title: 'What Does TEFL Iberia Recommend At This Point?',
    description: listFaq5,
  },
];

const Covid = () => {
  return (
    <Layout
      title="Our Covid-19 Protocols and Policy | TEFL Iberia"
      description="We've introduced several new safety and hygiene measures to keep our students safe at
    the school. Find out more."
    >
      <div className="covid-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">We are open!</h1>
            <h1 className="description">Please read our Covid-19 response</h1>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7 order-md-first order-last">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">A note from the School Director</h2>
                  <p className="c-text-18 block__subtitle">
                    Like all of you, I am concerned about the spread of COVID-19 in Spain and abroad
                    and its potential effect on students, employees, and community members.
                  </p>
                  <p className="c-text-16 block__description">
                    I believe these circumstances present a unique opportunity to learn and adapt
                    and we at TEFL Iberia are prepared to tackle the challenge head on. The last
                    year has been very challenging, but we’re pleased to say Spain has opened back
                    up and is allowing regular travel. Barcelona is too beautiful and interesting a
                    city to stay away from, and we look forward to welcoming back regular groups of
                    students! Follow{' '}
                    <a href="https://www.spth.gob.es/" target="_blank" className="red-link">
                      this link
                    </a>{' '}
                    for up-to-date entrance requirements from the Spanish government.
                    <br />
                    <br />
                    We continue to follow guidance from the local authorities to keep the school as
                    safe as possible and detail these measures below.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5 order-md-last order-first">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgPhoto1} alt="" className="block_r__img" />
                  </div>

                  <div className=" block_r__trinity">
                    <img src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-py">
          <div className="container top">
            <h2 className="third-section__title c-title-34">
              How are we protecting our students and staff?
            </h2>
            <p className="third-section__description c-text-18">
              We have the following safety measures in place:
            </p>
            <div className="row hygiene">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <div className="item__img">
                    <img src={img3} alt="" />
                  </div>
                  <div className="item__content c-benefit-list">
                    <h3 className="item__title">Physical Distancing</h3>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Desks and chairs will be 2 metres apart.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Staggered classes to avoid congestions.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Mandatory use of face masks.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Masks are available for sale at reception.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <div className="item__img">
                    <img src={img1} alt="" />
                  </div>
                  <div className="item__content c-benefit-list">
                    <h3 className="item__title">Regular Disinfection</h3>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Hand sanitizer and soap is readily available. </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Classrooms will be disinfected between classes.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Bathrooms will be cleaned regularly.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Classrooms will be properly ventilated.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <div className="item__img">
                    <img src={img2} alt="" />
                  </div>
                  <div className="item__content c-benefit-list">
                    <h3 className="item__title">Kitchen Area Closed</h3>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Coffee, tea, fruit and biscuits will be temporarily unavailable.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Cups, mugs and dishes will also be unavailable.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        We ask that you bring your own coffee thermos or water bottle.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Eating in the school is temporarily prohibited. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="third-section c-faqs-section">
              <div className="container">
                <div className="c-faqs-section__title">
                  <h2 className="c-title-34 ">Frequently Asked Questions</h2>
                  {/* <Link to="/courses/faq" className="c-btn c-btn--white">
                    View More FAQs
                    <IconArrowRightRed />
                  </Link> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <Faq description={faqs[0].description} title={faqs[0].title} />
                    {/* <Faq description={faqs[1].description} title={faqs[1].title} /> */}
                    <Faq description={faqs[2].description} title={faqs[2].title} />
                  </div>
                  <div className="col-lg-6">
                    <Faq description={faqs[3].description} title={faqs[3].title} />
                    <Faq description={faqs[4].description} title={faqs[4].title} />
                  </div>
                </div>
              </div>
            </section>

            {/* <h2 className="third-section__title c-title-34">FAQs: Coronavirus & TEFL</h2>
            <div className="devider">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="block_l">
                    <h3 className="c-title-20">
                      What is the current reality for teaching English overseas?
                    </h3>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="block_r">
                    <p className="c-text-16">
                      The reality is that many schools have either gone completely online or have
                      opted for a hybrid model. Governments all over the world have put restrictions
                      on how many people can gather in one place at a time, which puts limitations
                      on how TEFL courses and English classes are run.
                      <br />
                      <br />
                      So if you do make it to Barcelona, for example, you’ll be expected to wear a
                      face mask / shield during your lesson and your students will have to wear a
                      face mask too. Classes will be limited to 6 (5 students and the teacher) and
                      there will be cleaning protocols in place between each class.
                      <br />
                      <br />
                      In terms of finding a job in a school, the demand is definitely reduced but it
                      is possible to find work. It’s just a case of being persistent and being in
                      the right place at the right time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="devider">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="block_l">
                    <h3 className="c-title-20">
                      The economy is weak. Is now a good time to invest in a TEFL course?
                    </h3>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="block_r">
                    <p className="c-text-16">
                      In short, yes, there is a large demand for online teachers and it is
                      relatively easy to find work.
                      <br />
                      <br />
                      <h4 className="block_r__list-title">
                        There are few points worth remembering before investing in a TEFL course:
                      </h4>
                      <ul className="block_r__list">
                        <li className="list__item">
                          Demand for online teachers was consistently rising before the pandemic and
                          is now at record levels.
                        </li>
                        <li className="list__item">
                          Education is more or less recession proof; many people use their downtime
                          to study or retrain.
                        </li>
                        <li className="list__item">
                          Governments have boosted funding for their Covid retraining schemes. See{' '}
                          <a
                            className="red-link"
                            href="https://www.gov.uk/government/news/major-expansion-of-post-18-education-and-training-to-level-up-and-prepare-workers-for-post-covid-economy"
                            target="_blank"
                          >
                            UK Government
                          </a>{' '}
                          and{' '}
                          <a
                            className="red-link"
                            href="https://www.regionalskills.ie/covid-19-government-supports/"
                            target="_blank"
                          >
                            Irish Government
                          </a>{' '}
                          websites for more information.
                        </li>
                        <li className="list__item">
                          The training and qualification you get now is valid forever and you can
                          use it to find online or classroom work all over the world.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="devider">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="block_l">
                    <h3 className="c-title-20">
                      Should I do the course online or wait until next summer?
                    </h3>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="block_r">
                    <p className="c-text-16">
                      We believe there is no point putting your life on hold or delaying what you
                      really want to do, with such an uncertain future. Even though there is news of
                      a vaccine we really don’t know when things will go completely back to normal
                      and we can’t put our lives on hold forever!
                      <br />
                      <br />
                      If you get qualified now you can get some experience online and you’ll be in
                      an excellent position to find classroom teaching work in the country of your
                      choice, when travel restrictions are relaxed.
                      <br />
                      <br />
                      Also, to give you an edge, we’re offering a free, 2-day classroom teaching
                      workshop in Barcelona for all students that complete our online Trinity
                      CertTESOL course.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="devider">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="block_l">
                    <h3 className="c-title-20">
                      I really want to come to Barcelona and teach. What should I do?
                    </h3>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="block_r">
                    <p className="c-text-16">
                      It is still possible! We recommend completing the online Trinity CertTESOL and
                      we can also help with your relocation to Barcelona (accommodation,
                      bureaucracy, visa) as we normally do. Contact{' '}
                      <a href="mailto:classes@ilabarcelona.com" className="red-link">
                        classes@ilabarcelona.com
                      </a>{' '}
                      for more information.
                      <br />
                      <br />
                      It is still possible to find work in schools in Barcelona, you’ll just have to
                      put a little more effort into your search.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="devider">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="block_l">
                    <h3 className="c-title-20">What does TEFL Iberia recommend at this point? </h3>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="block_r">
                    <h4 className="block_r__list-title">
                      There are few points worth remembering before investing in a TEFL course:
                    </h4>
                    <p className="c-text-16">
                      <ol>
                        <li>Stay safe!</li>
                        <li>Do the online Trinity CertTESOL to get qualified.</li>
                        <li>Build up your experience teaching online this winter.</li>
                        <li>
                          Come to Barcelona to participate in your free 2-day workshop when you are
                          ready.
                        </li>
                        <li>Find your dream job in Barcelona when the time is right.</li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="container bottom">
            <h2 className="bottom__title c-title-28">How are we helping?</h2>

            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <div className="item__img">
                    <img src={imgBenefit1} alt="" />
                  </div>
                  <div className="item__content c-benefit-list">
                    <h3 className="item__title">Reduced prices on our courses</h3>
                    <p className="item__description">
                      To support individuals in retraining or to find new employment opportunities
                      we have reduced our course fee from 1650€ to 1500€, to make the course as
                      accessible to as many people as possible.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <div className="item__img">
                    <img src={imgBenefit2} alt="" />
                  </div>
                  <div className="item__content c-benefit-list">
                    <h3 className="item__title">Increased our online offering</h3>
                    <p className="item__description">
                      We now run a fully 100% online Trinity CertTESOL course, for those that can’t
                      make it to Barcelona or want to maintain strict social distancing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="item">
                  <div className="item__img">
                    <img src={imgBenefit3} alt="" />
                  </div>
                  <div className="item__content c-benefit-list">
                    <h3 className="item__title">More flexible refund policy</h3>
                    <p className="item__description">
                      We’ve updated our refund policy to reflect the current circumstances due to
                      Covid-19.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Covid;
